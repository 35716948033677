{
  "name": "blanco-admin-fe",
  "version": "2.0.1-0f1a9f5c.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.1",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.1",
    "@angular/compiler": "^19.1.1",
    "@angular/core": "^19.1.1",
    "@angular/forms": "^19.1.1",
    "@angular/localize": "^19.1.1",
    "@angular/material": "^19.1.0",
    "@angular/platform-browser": "^19.1.1",
    "@angular/platform-browser-dynamic": "^19.1.1",
    "@angular/router": "^19.1.1",
    "core-js": "^3.40.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.2",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "^19.1.2",
    "@angular/compiler-cli": "^19.1.1",
    "@angular/language-service": "^19.1.1",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19",
    "@sparbanken-syd/sparbanken-syd-qr": "^19",
    "@sparbanken-syd/sparbanken-syd-theme": "^19",
    "@types/jasmine": "~5.1.5",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "^22",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "@typescript-eslint/eslint-plugin": "^8.20.0",
    "@typescript-eslint/parser": "^8.20.0",
    "angular-eslint": "^19.0.2",
    "eslint": "^9.18.0",
    "eslint-plugin-import": "2.31.0",
    "eslint-plugin-jsdoc": "^50.6.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.5.1",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.13.2",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.10.1",
    "typescript": "~5.6.3",
    "typescript-eslint": "^8.20.0"
  }
}
